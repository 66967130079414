/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Las identificación en Internet es cada día mas necesaria, muchísimas paginas, todas las que ofrecen servicios ofrecen su sistema de autenticación."), "\n", React.createElement(_components.p, null, "En Internet entramos en decenas de sitios distintos, aunque Google y Facebook intenten que solo entremos en los suyos, por eso necesitamos un sistema de autenticación independiente de empresas y sus intereses privados."), "\n", React.createElement(_components.p, null, "Para esto tenemos OpenID, un estándar de identificación descentralizado, nosotros nos podremos registrar en un servidor de OpenID, como puede ser nuestro blog wordpress.com y este nos proporcionará una URL. Una vez autenticado en nuestro servidor pondremos nuestra URL en el campo OpenID de una pagina servidor de este sistema y ya estaremos identificados."), "\n", React.createElement(_components.p, null, "Las ventajas de este sistema son incontables, además van apareciendo nuevas versiones que van mejorándolo, el problema es que aunque hay muchos servidores de OpenID, todos los grandes se han apuntado(Google, Yahoo,  Wordpress.com…), pocos son los que deciden hacerse clientes, tan solo blogs y uno de los grandes Facebook."), "\n", React.createElement(_components.p, null, "Espero que se empiece a expandir este estándar pues tiene muchas posibilidades ¿Cuales? Lo veremos pronto."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
